import React,{ Fragment, useContext, useState, useEffect } from 'react'
import { Sprite } from '@inlet/react-pixi'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'
import { StoreContext } from 'components/Data/StoreContext'

import './Face.scss'

const Face = ({ character, store, scale, startX, startY }) =>
{
	return (
	<Sprite
		image={store.characterAssets[character.uuid+"-face"]}
		scale={scale}
		anchor={0.5}
		x={startX}
		y={startY}
	/>
	)
}

export default Face