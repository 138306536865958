import React, { useRef,useContext} from 'react'
import _isEmpty from 'lodash/isEmpty'
import { StoreContext } from 'components/Data/StoreContext'
import './Footer.scss'
import NormalLayout from './NormalLayout'
import MiniLayout from './MiniLayout'


const Footer = props => 
{
	let store = useContext(StoreContext)
	const data = useRef()

	if(!_isEmpty(store.options))
	{
		let options = store.options

		options.phone_number.dial = options.phone_number.number.replace(/\./g,'-')
		if(options.phone_number.dial[0] !== '1')
			options.phone_number.dial = '1-'+options.phone_number.dial

		options.email_encoded = (options.email || 'hello@conceptarts.com').replace(/[\u00A0-\u9999<>\&]/gim, i => {
			return '&#'+i.charCodeAt(0)+';'
		})

		data.current = options
	}
	else
	{
		return null
	}

	return (
		<footer 
			 id='footer'
			 data-layout={props.layout}
		>
			{props.layout === 'normal' ? <NormalLayout data={data.current} /> : <MiniLayout data={data.current} />}

			{/* <button onClick={e => props.showModal('privacy',{})}>PRIVACY</button>  */}
		</footer>
	)
}

Footer.defaultProps = 
{
	layout: 'full'
}

export default Footer