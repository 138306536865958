/* global CA */
import React, { Fragment,useEffect,useState,useRef,useCallback,useContext } from 'react'
import Helmet from 'react-helmet'
import { isMobile } from 'react-device-detect'
import { TweenLite,TimelineLite,Power2,Power3 } from 'gsap/all'
import _filter from 'lodash/filter'
import _each from 'lodash/each'
import _map from 'lodash/map'
import _shuffle from 'lodash/shuffle'
import _difference from 'lodash/difference'
import _random from 'lodash/random'
import _keyBy from 'lodash/keyBy'
import { Waypoint } from 'react-waypoint'

import EventDispatcher from '@ca/eventdispatcher'
import { pageVisibilityHandlers,useScrollPosition } from 'components/Utils/Utils'
import { StoreContext } from 'components/Data/StoreContext'
import { Player,ControlBar,BigPlayButton,LoadingSpinner,PlayToggle,FullscreenToggle } from 'video-react'
import Footer from 'components/Footer/Footer'

import 'video-react/styles/scss/video-react.scss'
import './About.scss'

// eslint-disable-next-line
const treeShakeBlocker = [Power2,Power3]

const About = props =>
{
	const store = useContext(StoreContext)
	const data = useRef({})
	const elRef = useRef()
	const gridRef = useRef()
	const playerRef = useRef()
	const employeesVisible = useRef()
	const employeeId = useRef()
	const timer = useRef()
	//const scrollPosition = useRef(0)

	const [_transitionIn,setTransitionIn] = useState(false)
	const [_employeesVisible,setEmployeesVisible] = useState([])
	
	const scrollPosition = useScrollPosition(elRef.current,TweenLite.ticker)
	
	const rotateEmployee = () =>
	{	
		if(!data.current.acf)
			return
			
		const employees = _map(data.current.acf.employee_photos, ({ image }) => { return image.id })
		let employees_visible = employeesVisible.current.slice()
		const unused = _difference(employees, employees_visible)
		const _in = unused[_random(0,unused.length-1)]
		
		let idx
		let el_out
		
		// make sure the flip target is visible
		do 
		{
			idx = _random(0,employees_visible.length-1)
			el_out = elRef.current.querySelector(`.grid .block[data-id="${employees_visible[idx]}"]`)
		}
		while((el_out.offsetTop + el_out.offsetHeight) > window.innerHeight);

		employeeId.current = _in

		if(el_out) 
		{
			let reveal = el_out.querySelector('.reveal')
			let photo = el_out.querySelector('.photo')

			TweenLite.killTweensOf(el_out)

			const TL = new TimelineLite();
			TL
				.to(photo, 0.45, { force3D:true, y:'51%', scale:1, autoAlpha:0, ease:Power2.easeInOut }, 0)
				.to(reveal, 0.45, { force3D:true, y:'100%', scale:1, ease:Power2.easeInOut }, 0)
				.call((idx,_in,employees_visible,setEmployeesVisible) => 
				{
					employees_visible[idx] = _in
					setEmployeesVisible(employees_visible)
				},[idx,_in,employees_visible,setEmployeesVisible])
			
		}
	}

	const startRotator = () =>
	{
		if(timer.current)
		{
			stopRotator()
		}
		else
		{
			rotateEmployee()
		}

		timer.current = setInterval(rotateEmployee,2000)
	}

	const stopRotator = () =>
	{
		if(timer.current)
		{
			clearInterval(timer.current)
			timer.current = null
		}
	}

	const getScrollOffsets = scrollPosition =>
	{
		const y = scrollPosition

		const pct = Math.max(0,y / (window.innerHeight * 1))
		const opacity = Math.min(1,Math.max(0,1 - (1 * pct)))
		
		return { y, pct, opacity }
	}

	// init
	//
	useEffect(() => 
	{
		store.analytics.pageView('/about')

		pageVisibilityHandlers('add',startRotator,stopRotator)

		return () =>
		{
			stopRotator()
			pageVisibilityHandlers('remove',startRotator,stopRotator)
		}

	},[])

	// data is loaded
	//
	useEffect(() =>
	{
		if(store.pages.length)
		{
			data.current = _filter(store.pages, { slug: store.pageId })[0]
			shuffleEmployees()
		}
	},[store.pages,elRef])

	
	useEffect(() =>
	{
		// hero grid animations
		//
		if(gridRef.current && _employeesVisible.length)
		{
			if(!_transitionIn)
			{
				animateIn(startRotator,0.75)
			}
			else
			{
				let id = _difference(_employeesVisible,employeesVisible.current)[0]
				const node = gridRef.current.querySelector(`.grid .block[data-id="${id}"]`)

				if(node) 
				{
					let reveal = node.querySelector('.reveal')
					let photo = node.querySelector('.photo')

					const TL = new TimelineLite()
					TL
						.fromTo(reveal, 1, { y:'100%', scale:1 }, { immediateRender:true, y:'-101%', scale:1, ease:Power2.easeInOut }, 0)
						.fromTo(photo, 1, { y:'51%', scale:1, autoAlpha:0 }, { immediateRender:true, y:'0%', scale:1, autoAlpha:1, ease:Power2.easeInOut }, 0)
				}
			}

			employeesVisible.current = _employeesVisible
		}
		
	})
	
	
	

	const animateIn = (cb,delay=0) =>
	{
		const gridBlocks = gridRef.current.querySelectorAll('.block')

		const dash = elRef.current.querySelectorAll('.lockup .dash')
		const title = elRef.current.querySelector('.lockup .title')
		let children = [].slice.call(elRef.current.querySelectorAll('.lockup .links .department'))
		children.unshift(title)
		
		const TL = new TimelineLite({ paused:true })

		TL.call(()=>{},[],null,1+delay)

		_each(_shuffle(gridBlocks), (v,i) => 
		{
			let reveal = v.querySelector('.reveal')
			let photo = v.querySelector('.photo')

			let TL2 = (new TimelineLite())
				.fromTo(reveal, 1, { y:'100%', scale:1 }, { force3D:true, immediateRender:true, y:'-101%', scale:1, ease:Power2.easeInOut }, 0)
				.fromTo(photo, 1, { y:'51%', scale:1, autoAlpha:0 }, { force3D:true, immediateRender:true, y:'0%', scale:1, autoAlpha:1, ease:Power2.easeInOut }, 0)
			
			TL.add(TL2, i > 0 ? '-=0.95' : '-=0.75')

		})

		TL.addLabel('lockup','-=0.5')

		TL
			.fromTo(dash, 0.5, { scale:0 }, { immediateRender:true, scale:1, ease:Power3.easeOut },'lockup')
			.staggerFromTo(children, 0.5, { autoAlpha:0, y:20 }, { immediateRender:true, autoAlpha:1, y:0, ease:Power3.easeOut },0.1,'-=0.5')

			.set(elRef.current, { className:'-=gsap' })
		
		if(cb)
			TL.call(cb)
		

		TL.timeScale(1).play(0)

		setTransitionIn(true)
		
	}

	const animateReveal = (dir='in',prevPosition='below',target,speedIn=1,callback) =>
	{
		let scrollDir = prevPosition === 'above' ? -1 : 1
		if(!target || isMobile)
			return

		// force prior tweens to finish so onComplete will fire
		//
			_each(TweenLite.getTweensOf(target), t =>
			{
				t.seek(t.duration())
			})

			TweenLite.killTweensOf(target)


		if(dir === 'out')
		{
			if(target.classList.contains('in')) {
				
				TweenLite.to(target, speedIn/2, { autoAlpha:0, onComplete: (target,callback) => {
					target.classList.remove('in')
					if(callback)
						callback()
				}, onCompleteParams:[target,callback] })
			}
		}
		else
		{
			if(!target.classList.contains('in')) {
				
				if(scrollDir === 1)
				{
					TweenLite.fromTo(target, speedIn,  { autoAlpha:0 }, { autoAlpha:1, delay:0, onComplete: (target,callback) => {
						target.classList.add('in')
						if(callback)
							callback()
					}, onCompleteParams:[target,callback] })
				}
				else
				{
					TweenLite.fromTo(target, speedIn,  { autoAlpha:0 }, { autoAlpha:1, delay:0, onComplete: (target,callback) => {
						target.classList.add('in')
						if(callback)
							callback()
					}, onCompleteParams:[target,callback] })
				}	
			}
		}

	}
	
	const shuffleEmployees = () => 
	{
		const _data = data.current && data.current.acf
		if(!_data)
			return

		const photos = data.current.acf.employee_photos
		const shuffled = _shuffle(photos.map(({ image }) => { return image.id }))

		setEmployeesVisible(shuffled.slice(0,35))
	}




	const tagline = useCallback(() =>
	{
		const _data = data.current && data.current.acf
		if(!_data)
			return null

		return data.current.acf.tagline

	},[data])

	const involvedDepartments = useCallback(() =>
	{
		const _data = data.current && data.current.acf
		if(!_data)
			return null
		
		return _data.departments.map((v,i) => 
		{
			return (
				<div className='department' key={'nav-link-'+i}  data-section={v.id} >
					<span className='text'>{v.department}</span>
					<span className='slash'>/</span>
				</div>
			)
		})
	},[data])

	const employeeGrid = useCallback(() =>
	{
		const _data = data.current && data.current.acf
		if(!_data)
			return null

		const employees = _keyBy(_data.employee_photos, v => { return v.image.id })

		return _map(_employeesVisible, id => 
		{
			const { image } = employees[id]
			
			let styles_photo = { backgroundImage:`url(${image.url})` }
			let styles_reveal = {}

			const active = id === employeeId.current

			// if(id === employeeId.current)
			// {
			// 	styles_photo.transform = 'translateY(51%)'
			// 	styles_photo.visibility = 'hidden'
			// 	styles_photo.opacity = '0'
			// 	styles_reveal.transform = 'translateY(100%)'
			// 	employeeId.current = null
			// }

			return (
				<div key={'peep-'+id} data-id={id} data-active={active} className='block'>
					<div className='photo' style={styles_photo}></div>
					<div className='reveal' style={styles_reveal}></div>
				</div>
			)

		})
	},[_employeesVisible])

	const companyStory = useCallback(() =>
	{
		const _data = data.current && data.current.acf
		if(!_data)
			return null

		const quoteImg = require('../../assets/images/about-quote.svg')
		const story = `<img class='quote' src=${quoteImg} /> ${_data.story}`
		const target = elRef.current && elRef.current.querySelector('.company-story')
					
		return (
			<Waypoint
				scrollableAncestor={elRef.current}
				horizontal={false}
				topOffset='250px'
				onEnter={e => {
					console.log('FARTS.onEnter()',e)
					animateReveal('in',e.previousPosition,target)
				}}
				onLeave={e => {
					console.log('FARTS.onLeave()',e)
					animateReveal('out',e.previousPosition,target)
				}}
			>
				<div 
					className='company-story' 
					dangerouslySetInnerHTML={{ __html:story}}
				></div>
			</Waypoint>
		);
	},[data])

	const reel = useCallback(() =>
	{
		const _data = data.current && data.current.acf
		if(!_data)
			return null

		let video

		if(_data.reel.type === 'html5') 
		{
			video = (
				<Player 
					ref={playerRef}
					
					fluid={true}
					width={720}
					height={480}
					
					playsInline
					autoPlay={false}
					muted={false}
					loop={false}
					poster={_data.reel.poster}
				>
					<source src={_data.reel.source} />
					<LoadingSpinner />
					<BigPlayButton disabled={false} position='center' />
					<ControlBar autoHide={!false} disableDefaultControls={true} disabled={!true}>
						<PlayToggle />
						<FullscreenToggle />
					</ControlBar>
				</Player>
			)

		} 
		else 
		{
			video = (
				<iframe 
					title='player'
					className='player'
					src={data.url} 
					width='640' 
					height='360' 
					frameBorder='0' 
					allowFullScreen
				>
				</iframe>
			)
		}
		
		return (
			<div className='reel'>
				<Waypoint
					scrollableAncestor={elRef.current}
					horizontal={false}
					topOffset='250px'
					onEnter={e => {
						let target = elRef.current && elRef.current.querySelector('.reel .media-wrapper');
						animateReveal('in',e.previousPosition,target,1,() => {
							/*
							if(playerRef.current)
								playerRef.current.play()
							*/
						})
					}}
					onLeave={e => {
						let target = elRef.current && elRef.current.querySelector('.reel .media-wrapper');
						animateReveal('out',e.previousPosition,target,1,() => {
							if(playerRef.current)
								playerRef.current.pause()
						})
					}}
				>
					<div className='media-wrapper'>
						{video}
					</div>
				</Waypoint>
			</div>
		)

	},[data])

	const officePhotos = useCallback(() =>
	{
		const officePhotos = require('../../assets/images/office-photos/new.jpg')
		
		return (
			<Waypoint
				scrollableAncestor={elRef.current}
				horizontal={false}
				topOffset='250px'
			>
				<div className='office-photos'>
					<img src={officePhotos} alt='' />
				</div>
			</Waypoint>
		);
	},[data])

	const companyBlurb = useCallback(() =>
	{
		//console.log("About::companyBlurb");

		const _data = data.current && data.current.acf
		if(!_data)
			return null

		const quoteImg = require('../../assets/images/about-quote-2.svg')
		let blurb = `<img class='quote' src=${quoteImg} /> ${_data.blurb}`

		return (
			<div className='company-blurb-wrapper'>
				<Waypoint
					scrollableAncestor={elRef.current}
					horizontal={false}
					topOffset='250px'
					onEnter={e => {
						let target = elRef.current && elRef.current.querySelector('.company-blurb');
						animateReveal('in',e.previousPosition,target);
					}}
					onLeave={e => {
						let target = elRef.current && elRef.current.querySelector('.company-blurb');
						animateReveal('out',e.previousPosition,target);
					}}
				>
					<div 
						className='company-blurb' 
						dangerouslySetInnerHTML={{ __html:blurb}}
					></div>
				</Waypoint>
			</div>
		)

	},[data])

	
	
	
	const { y,opacity } = getScrollOffsets(scrollPosition)
	EventDispatcher.dispatch('page-scroll', { target: elRef.current, scrollTop: scrollPosition })
		
	
	return (
		<Fragment>
			<Helmet>
				<title>About Concept Arts</title>
				<meta name='title' content='About Concept Arts' />
			</Helmet>
			<div 
				data-page-id='about' 
				className='page'
				ref={elRef}
			>
				<div className='hero'>
					<div 
						className='grid' 
						ref={gridRef}
						style={{ transform: `translate(0px,${y}px)`, opacity:opacity }}
					>
						{employeeGrid()}
					</div>
					
					<div className='lockup'>
						<div className='dash'></div>
						<h2 className='title'>{tagline()}</h2>
						<div className='links'>
							{involvedDepartments()}
						</div>
					</div>
						
				</div>
				
				<div className='content'>
				
					{companyStory()}

					{reel()}

					{officePhotos()}

					{companyBlurb()}
				</div>

				<Footer layout='normal' />
			</div>
		</Fragment>
	)
}

export default About