import React,{ Fragment, useContext, useState } from 'react'
import { Sprite } from '@inlet/react-pixi'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'

import './Mouth.scss'

const Mouth = ({ character, store, scale, startX, startY, multiplier }) =>
{

	return (
	<Sprite
		image={store.characterAssets[character.uuid+"-mouth"]}
		scale={scale}
		anchor={0.5}
		x={startX + character.mouth.offsetX * multiplier}
		y={startY + character.mouth.offsetY * multiplier}
	/>
	)
}

export default Mouth