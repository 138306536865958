import React,{ Fragment } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'

import {
	TelegramShareButton,
	WhatsappShareButton,
	ViberShareButton,
	LineShareButton,
	EmailShareButton,
	TelegramIcon,
	WhatsappIcon,
	ViberIcon,
	LineIcon,
	EmailIcon
  } from 'react-share';

import './Modal.scss'

const Modal = props =>
{
	if(!props.type)
		return null

	const Content = () =>
	{
		let content = ''

		switch(props.type)
		{
			case 'share':
	
				content = (
					<Fragment>
						<header>
							<h3>Share your message:</h3>
						</header>
						<div className='message'>
							<div className="network">
								<TelegramShareButton
									url={props.url}
									title={props.title}
									className="network-share-button">
									<TelegramIcon size={32} round />
								</TelegramShareButton>
							</div>

							<div className="network">
								<WhatsappShareButton
									url={props.url}
									title={props.title}
									className="network-share-button">
									<WhatsappIcon size={32} round />
								</WhatsappShareButton>
							</div>

							<div className="network">
								<ViberShareButton
									url={props.url}
									title={props.title}
									className="network-share-button">
									<ViberIcon size={32} round />
								</ViberShareButton>
							</div>

							<div className="network">
								<LineShareButton
									url={props.url}
									title={props.title}
									className="network-share-button">
									<LineIcon size={32} round />
								</LineShareButton>
							</div>

							<div className="network">
								<EmailShareButton
									url={props.url}
									subject={props.title}
									className="network-share-button">
									<EmailIcon size={32} round />
								</EmailShareButton>
							</div>
						</div>
					</Fragment>
				)
				
				break;

			case 'error':

					content = (
						<Fragment>
							<header>
								<h3>Error!!</h3>
							</header>
							<div className='message'>
								{props.message}
							</div>
						</Fragment>
					)
					
					break;
	
			case 'legal':
			
				content = (
					<Fragment>
						<header>
							<h3>Legal</h3>
						</header>
						<ol>
							<li>Stuff and things...</li>
						</ol>
					</Fragment>
				)
				
				break;
			
			default:
		}

		return content;
	}

	return (
		<Transition
			native
			reset
			items={window.location}
			keys={location => location.pathname}
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0, pointerEvents: 'none' }}
		>
			{ tprops => style => 
				<animated.div 
					id='modal'
					style={style}
				>
					<div className='bg' onClick={props.closeModal}></div>
					<div className='content'>
						<button className='x' onClick={props.closeModal}>X</button>
						<Content/>
					</div>
				</animated.div>
			}
		</Transition>
	)
}

export default Modal