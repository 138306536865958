import React,{ Fragment, useContext, useState, useRef, useEffect } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'
import { StoreContext } from 'components/Data/StoreContext'
import { Stage, useTick } from '@inlet/react-pixi'
import Face from 'components/Character/Face'
import Mouth from 'components/Character/Mouth'
import request from 'components/Utils/Request'
import uuidv4 from 'uuid/v4'
import MediaRecorder from 'audio-recorder-polyfill'
import lamejs from 'lamejs'

import './RecordAudio.scss'

//MediaRecorder.encoder = require('audio-recorder-polyfill/mp3-encoder')
//MediaRecorder.prototype.mimeType = 'audio/mpeg'
//MediaRecorder.prototype.encoderConfig = {lamejsPath: process.env.REACT_APP_SITEURL+'/assets/js/lame.min.js', kbps:128}

const RecordAudio = props =>
{	
	const store = useContext(StoreContext)
	const [recorderLoading,setRecorderLoading] = useState(false)
	const [recording,setRecording] = useState(false)
	const [recorded,setRecorded] = useState(false)
	const [playing,setPlaying] = useState(false)
	const [characterIndex,setCharacterIndex] = useState(0)
	const [mp3Encoded,setMp3Encoded] = useState(false);

	const wav = useRef();
	const mp3 = useRef();
	const bufferSource = useRef();
	const rawAudioData = useRef();
	const audioData = useRef();
	const analyser = useRef(null);
	const biquadFilter = useRef();
	const dataArray = useRef();
	const sourceMicrophone = useRef();
	const binRange = useRef();
	const lowFreq = useRef();
	const hightFreq = useRef();
	const startBin = useRef();
	const endBin = useRef();
	const binCount = useRef();
	const mediaRecorder = useRef();
	const microphoneClone = useRef();

	let selectedCharacter = store.settings.characters[characterIndex];

	const toggleRecord = async () => {
		
		setRecorderLoading(true);
	
		if (recording)
		{
			mediaRecorder.current.stop()

			setRecorded(true);
			setRecorderLoading(false);
			setRecording(false);
		} 
		else 
		{
			sourceMicrophone.current.disconnect();
			microphoneClone.current.getTracks().forEach(i => i.stop())

			mediaRecorder.current = new MediaRecorder(store.microphone);

			mediaRecorder.current.addEventListener('dataavailable', e => {

				wav.current = e.data;

				let reader = new FileReader();

				reader.onloadend = () =>
				{
					rawAudioData.current = reader.result;
				};
				
				reader.readAsArrayBuffer(wav.current);
			  })

			mediaRecorder.current.start()
			
			setRecorderLoading(false);
			setRecording(true);
		}
	};

	const togglePlay = () => 
	{
		if(playing)
		{
			//console.log("stop");
			bufferSource.current.stop();
			bufferSource.current.disconnect();
			setPlaying(false);
		}
		else
		{
			bufferSource.current = store.audioContext.createBufferSource();
			bufferSource.current.onended = playbackEnded;

			audioData.current = rawAudioData.current.slice(0)

			store.audioContext.decodeAudioData(audioData.current,(buffer) => 
			{
				bufferSource.current.buffer = buffer;
				bufferSource.current.connect(biquadFilter.current);
				biquadFilter.current.connect(analyser.current);
				bufferSource.current.connect(store.audioContext.destination);
		
				bufferSource.current.start(0);
				setPlaying(true);

			},(e) => 
			{
				console.log("Error with decoding audio data: " + e);

			});
		}
	}

	const playbackEnded = () =>
	{
		bufferSource.current.disconnect();
		setPlaying(false);
	}

	const reRecord = () => 
	{
		setMp3Encoded(false);
		setPlaying(false);
		setRecorded(false);

		bufferSource.current.disconnect();
		microphoneClone.current = store.microphone.clone();
		sourceMicrophone.current = store.audioContext.createMediaStreamSource(microphoneClone.current);
		sourceMicrophone.current.connect(biquadFilter.current);
		biquadFilter.current.connect(analyser.current);
	}

	const initAudio = () =>
	{				
		analyser.current = store.audioContext.createAnalyser();
		analyser.current.smoothingTimeConstant = .2;
		analyser.current.fftSize = 1024;
		
		biquadFilter.current = store.audioContext.createBiquadFilter();
		biquadFilter.current.type = 'bandpass';
		biquadFilter.current.frequency.value = 690;
		biquadFilter.current.Q.value = 10;

		microphoneClone.current = store.microphone.clone();
		sourceMicrophone.current = store.audioContext.createMediaStreamSource(microphoneClone.current);
		sourceMicrophone.current.connect(biquadFilter.current);
		biquadFilter.current.connect(analyser.current);

		dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);

		binRange.current = (store.audioContext.sampleRate/analyser.current.fftSize);
		lowFreq.current = 85;
		hightFreq.current = 7000;
		startBin.current = Math.round(lowFreq.current/binRange.current);
		endBin.current = Math.round(hightFreq.current/binRange.current);
		binCount.current = endBin.current - startBin.current;
	}

	const destroyAudio = () =>
	{
		console.log("RecordAudio::destroyAudio");

		biquadFilter.current.disconnect();
		analyser.current.disconnect();
		sourceMicrophone.current.disconnect();
		sourceMicrophone.current = null;
		bufferSource.current.disconnect();
	}

	useEffect(() => 
	{
		initAudio();

		return () =>
		{
			destroyAudio();
		}

	},[])

	const getAverageVolume = (array) =>
	{
        var amplitudeSum = 0;

		for (var i = 0; i < array.length; i++)
		{
            amplitudeSum += array[i];
        }

		return amplitudeSum / array.length;
	}

	const encodeMono = (channels, sampleRate, samples) => {
		
		const buffer = [];
        const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
        let remaining = samples.length;
		const maxSamples = 1152;
		
		for (var i = 0; remaining >= maxSamples; i += maxSamples) 
		{
            let mono = samples.subarray(i, i + maxSamples);
            let mp3buf = mp3enc.encodeBuffer(mono);
			
			if (mp3buf.length > 0) 
			{
                buffer.push(new Int8Array(mp3buf));
			}
			
            remaining -= maxSamples;
		}
		
		let d = mp3enc.flush();
		
		if(d.length > 0)
		{
            buffer.push(new Int8Array(d));
		}
		
		//console.log('done encoding, size=', buffer.length);
		
		setMp3Encoded(true);
		
		return new Blob(buffer, {type: 'audio/mpeg'});
    }

	const Share = async () => {
		console.log("RecordAudio::Share");

		const uniqueFilename = uuidv4();

		const data = new FormData();

		if(!mp3Encoded)
		{
			let wavHeader = lamejs.WavHeader.readHeader(new DataView(rawAudioData.current));		
			let samples = new Int16Array(rawAudioData.current, wavHeader.dataOffset, wavHeader.dataLen / 2);
			mp3.current = await encodeMono(wavHeader.channels, wavHeader.sampleRate, samples);
		}

		data.append('characteruuid', selectedCharacter.uuid);
		data.append('audio', mp3.current, uniqueFilename+'.mp3');

		request.post(`/upload.php`, data,{
			headers: {
			  'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
			},
			timeout: 30000,
		}).then(response => {
			store.showModal({type:'share',url:process.env.REACT_APP_SITEURL+"?messageid="+response.data.messageuuid,title:process.env.REACT_APP_TITLE});
		});
	}

	const Character = () => {
	
		const [volume,setVolume] = useState(0)

		let scale = Math.min(800 / selectedCharacter.face.width, 800 / selectedCharacter.face.height);

		useTick(delta =>
		{	
			if(recording)
			{
				setVolume(0.0);
			}
			else
			{	
				if(analyser.current)
				{
					analyser.current.getByteFrequencyData(dataArray.current);

					var amplitudeSum = 0;

					for (var i = startBin.current; i < endBin.current; i++)
					{
						amplitudeSum += dataArray.current[i];
					}

					setVolume(amplitudeSum / binCount.current / 255.0);
					//setVolume(getAverageVolume(dataArray.current)/255.0);
				}
			}
		})

		return (
			<Fragment>
				<Mouth character={selectedCharacter} store={store} scale={scale} startX={400} startY={400} multiplier={volume} />
				<Face character={selectedCharacter} store={store} scale={scale} startX={400} startY={400} />
			</Fragment>
		)
	}

	const recordingControls = () =>
	{
		return(
		<Fragment>
			<button onClick={toggleRecord}>
			{recording ? 'Stop' : 'Record'}
			</button>
		</Fragment>
		)
	}

	const playbackControls = () =>
	{
		return(
			<Fragment>
				<button onClick={reRecord}>
					{'Re-Record'}
				</button>
				<button onClick={togglePlay}>
					{playing ? 'Stop' : 'Play'}
				</button>
				<button onClick={Share}>
					{'Share'}
				</button>
			</Fragment>
		)
	}

	const Controls = () =>
	{
		let markup = '';

		if(!recorded || recording)
		{
			markup = recordingControls();
		}
		else
		{
			markup = playbackControls();
		}

		return markup;
	}

	const previousCharacter = () => 
	{
		if(characterIndex - 1 < 0)
		{
			setCharacterIndex(store.settings.characters.length - 1);
		}
		else
		{
			setCharacterIndex(characterIndex - 1);
		}
	}

	const nextCharacter = () => 
	{
		if(characterIndex + 1 > store.settings.characters.length - 1)
		{
			setCharacterIndex(0);
		}
		else
		{
			setCharacterIndex(characterIndex + 1);
		}
	}

	const Overlay = () =>
	{
		let markup = '';

		if(recording)
		{
			markup = (
				<Fragment>
					<div className="overlay">
						<div className="container">
							RECORDING...
						</div>
					</div>
				</Fragment>
			)
		}

		return markup;
	}


	return (
		<div id="record-audio">
			<div className="character">
				<Overlay />
				<Stage width={800} height={800}>
					<Character/>
				</Stage>
			</div>
			<div className="ui">
				<div className="name">{selectedCharacter.name}</div>
				<div className="controls">
					<div className="upper">
						<button onClick={previousCharacter}>
							{'<--'}
						</button>
						<button onClick={nextCharacter}>
							{'-->'}
						</button>
					</div>
					<div className="lower">
						<Controls />
					</div>
				</div>
			</div>
		</div>
	);
}

export default RecordAudio