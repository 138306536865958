import React,{ Fragment, useContext, useState, useRef, useEffect } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'
import { StoreContext } from 'components/Data/StoreContext'
import { Stage, useTick } from '@inlet/react-pixi'
import Face from 'components/Character/Face'
import Mouth from 'components/Character/Mouth'
import request from 'components/Utils/Request'
import _filter from 'lodash/filter'
import { withRouter } from 'react-router-dom'

import './Message.scss'

const Message = ({history,...props}) =>
{	
	const store = useContext(StoreContext)
	const [playing,setPlaying] = useState(false)
	const [selectedCharacter,setSelectedCharacter] = useState(null);

	const audioContextInitialized = useRef(false);
	const bufferSource = useRef();
	const rawAudioData = useRef();
	const audioData = useRef();
	const analyser = useRef();
	const biquadFilter = useRef();
	const dataArray = useRef();
	const binRange = useRef();
	const lowFreq = useRef();
	const hightFreq = useRef();
	const startBin = useRef();
	const endBin = useRef();
	const binCount = useRef();

	const togglePlay = () => 
	{
		//console.log("togglePlay::player: " + playing);

		if(playing)
		{
			//console.log("stop");
			bufferSource.current.stop();
			setPlaying(false);
		}
		else
		{
			if(!audioContextInitialized.current)
			{
				analyser.current = store.audioContext.createAnalyser();
				analyser.current.smoothingTimeConstant = .2;
				analyser.current.fftSize = 1024;
				
				biquadFilter.current = store.audioContext.createBiquadFilter();
				biquadFilter.current.type = 'bandpass';
				biquadFilter.current.frequency.value = 690;
				biquadFilter.current.Q.value = 10;

				dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);

				binRange.current = (store.audioContext.sampleRate/analyser.current.fftSize);
				lowFreq.current = 85;
				hightFreq.current = 7000;
				startBin.current = Math.round(lowFreq.current/binRange.current);
				endBin.current = Math.round(hightFreq.current/binRange.current);
				binCount.current = endBin.current - startBin.current;

				audioContextInitialized.current = true;
			}

			bufferSource.current = store.audioContext.createBufferSource();
			bufferSource.current.onended = playbackEnded;

			audioData.current = rawAudioData.current.slice(0)

			store.audioContext.decodeAudioData(audioData.current,(buffer) => 
			{
				bufferSource.current.buffer = buffer;
				bufferSource.current.connect(biquadFilter.current);
				biquadFilter.current.connect(analyser.current);
				bufferSource.current.connect(store.audioContext.destination);
		
				bufferSource.current.start(0);
				setPlaying(true);

			},(e) => 
			{
				console.log("Error with decoding audio data: " + e);
			});
		}
	}

	const playbackEnded = () =>
	{
		setPlaying(false);
	}

	useEffect(() => 
	{
		console.log("Message:useEffect");

		const fetchMessage = async () => 
		{
			const data = new FormData();
			data.append('messageuuid', props.messageID);

			const messageRequest = await request.post(`/message.php`, data,{
				headers: {
					'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				},
				timeout: 30000,
			});

			const audioRequest = await request.get(messageRequest.data.audiourl, {
				responseType: 'arraybuffer',
				timeout: 30000,
			  });

			rawAudioData.current = audioRequest.data;

			setSelectedCharacter(_filter(store.settings.characters, { uuid: messageRequest.data.characteruuid })[0]);
		};

		if(props.messageID === 'test')
		{
			request.get(process.env.REACT_APP_SITEURL+'/assets/'+props.messageID+'.mp3', {
				responseType: 'arraybuffer',
				timeout: 30000,
			}).then((result) =>
			{
				//console.log(result.data);
				rawAudioData.current = result.data;
			}).catch((err) => 
			{
				/* handle the error */
				console.log('there was an error getting the file as an arraybuffer');
			});

			setSelectedCharacter(_filter(store.settings.characters, { uuid: "f18bc3cb-6ba6-4254-a94e-4bc2f0ca1b59" })[0]);
		}
		else
		{
			fetchMessage();
		}

		return () =>
		{
			biquadFilter.current.disconnect();
			analyser.current.disconnect();
			bufferSource.current.disconnect();
		}

	},[])

	const getAverageVolume = (array) =>
	{
        var amplitudeSum = 0;

		for (var i = 0; i < array.length; i++)
		{
            amplitudeSum += array[i];
        }

		return amplitudeSum / array.length;
	}

	const share = () =>
	{
		console.log("Message::Share");
		store.showModal({type:'share',url:window.location.href,title:process.env.REACT_APP_TITLE});
	}

	const Character = () =>
	{
		const [volume,setVolume] = useState(0);

		let scale = Math.min(800 / selectedCharacter.face.width, 800 / selectedCharacter.face.height);

		useTick(delta =>
		{		
				if(audioContextInitialized.current)
				{
					analyser.current.getByteFrequencyData(dataArray.current);
					
					var amplitudeSum = 0;

					for (var i = startBin.current; i < endBin.current; i++)
					{
						amplitudeSum += dataArray.current[i];
					}

					setVolume(amplitudeSum / binCount.current / 255.0);

					//setVolume(getAverageVolume(dataArray.current)/255.0);
				}
		})

		return (
			<Fragment>
				<Mouth character={selectedCharacter} store={store} scale={scale} startX={400} startY={400} multiplier={volume} />
				<Face character={selectedCharacter} store={store} scale={scale} startX={400} startY={400} />
			</Fragment>
		)
	}

	const recordMessage = () =>
	{
		console.log("Message::recordMessage");
		history.push('/')
	}

	const playbackControls = () =>
	{
		return(
			<Fragment>
				<button onClick={recordMessage}>
					{'Record A Message'}
				</button>
				<button onClick={togglePlay}>
					{playing ? 'Stop' : 'Play'}
				</button>
				<button onClick={share}>
					{'Share'}
				</button>
			</Fragment>
		)
	}

	const Controls = () =>
	{
		let markup = "";

		if(selectedCharacter)
		{
			markup = playbackControls();
		}

		return markup;
	}

	return (
		<div id="message-audio">
			<div className="character">
				<Stage width={800} height={800}>
					{selectedCharacter ? <Character/> : null}
				</Stage>
			</div>
			<div className="controls">
				<Controls />
			</div>
		</div>
	);
}

export default withRouter(Message)