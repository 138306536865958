import React from 'react'
import { hydrate } from 'react-dom'
import { render } from 'react-snapshot'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AppContainer } from 'react-hot-loader'


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

render(
	<AppContainer>
		<App />
	</AppContainer>,
	document.getElementById('root')
)


if(module.hot)
{
	module.hot.accept('./App', () =>
	{
		render(
			<AppContainer>
				<App />
			</AppContainer>,
			document.getElementById('root')
		)
	})
}