import React, { Fragment } from 'react'
import { BrowserRouter as Router,Link } from 'react-router-dom'

const NavLinks = props =>
{
	return (
		<Fragment>
			<Link key='nav-menu-item-1' className='home' to='/'>
				<div className='idx'>01/04</div>
				<br />
				<div className='dash'></div>
				<div className='title'>HOME</div>
				<div className='subtitle'></div>
			</Link>
			<Link key='nav-menu-item-2' className='featured' to='/featured'>
				<div className='idx'>02/04</div>
				<br />
				<div className='dash'></div>
				<div className='title'>FEATURED</div>
				<div className='subtitle'>latest work</div>
			</Link>
			<Link key='nav-menu-item-3' className='about' to='/about'>
				<div className='idx'>03/04</div>
				<br />
				<div className='dash'></div>
				<div className='title'>ABOUT</div>
				<div className='subtitle'>our studio</div>
			</Link>
			<Link key='nav-menu-item-4' className='legacy' to='/legacy'>
				<div className='idx'>04/04</div>
				<br />
				<div className='dash'></div>
				<div className='title'>LEGACY</div>
				<div className='subtitle'>coming soon</div>
			</Link>
		</Fragment>
	)
}

export default NavLinks