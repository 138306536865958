import React, { useState,useEffect,useContext} from 'react'
import { TweenLite,TimelineLite,CSSPlugin,Power2,Linear,Expo } from 'gsap/all'
import { StoreContext } from 'components/Data/StoreContext'
import { ReactComponent as LogoType } from 'assets/images/C.svg'

import './Swipe.scss'

// eslint-disable-next-line
const treeShakeBlocker = [CSSPlugin,Power2,Linear,Expo]

const _refs = {}

const Swipe = props =>
{
	let store = useContext(StoreContext)
	
	const [ _animate,setAnimate] = useState(false)
	const animate = props.animate
	
	const transitionSwipe = (step,cb=()=>{}) => 
	{
		const swipe = _refs.el
		const TL = new TimelineLite({ paused:true, onComplete:cb })

		TL
			.fromTo(swipe, 1.2, { y:'100%' }, { immediateRender:true, y:'-100%', ease:Power2.easeInOut },0)
			.call(store => 
			{
				 store.eventDispatcher.dispatch('transition-swipe')
			},[store],this,0)
			.fromTo(swipe, 1.1375, { boxShadow: '0px 0px 0px #1f1f1f' }, { immediateRender:true, boxShadow: '0px -100vh 0px #1f1f1f', ease:Power2.easeInOut },0.0625)
		

		switch(step)
		{
			case 'A':
				
				TweenLite.to(TL, 0.6, { progress:0.5, ease:Linear.easeNone, onComplete:cb })

				break

			case 'B':
				
				TweenLite.fromTo(TL, 1.1, { progress:0.5 }, { progress:1, ease:Linear.easeNone })

				break

			default:
				
				TL.play(0)
		}
	}

	useEffect(() =>
	{
		if(animate !== _animate)
		{
			setAnimate(animate)
			return
		}

		if(store.history.length)
			transitionSwipe()

	},[animate,_animate])
	
	return (
		<div 
			id="swipe"
			ref={e => { if(e) _refs.el = e }}
		>
			<LogoType className='logotype' />
		</div>
	)

}

export default Swipe