import React from 'react'
import { Spring,animated,config } from 'react-spring/renderprops'
import { BrowserRouter as Router,Link } from 'react-router-dom'
import SocialLinks from 'components/Social/SocialLinks'

import './Masthead.scss'

const Masthead = props =>
{
	return (
		<div 
			id='masthead'
		>
			{/*
			<SocialLinks

			/>
			*/}
		</div>
	)
}

export default Masthead