/* global */
import React, { Fragment,useEffect,useContext,useRef } from 'react'
import _filter from 'lodash/filter'
import { StoreContext } from 'components/Data/StoreContext'
import Footer from 'components/Footer/Footer'

import './NotFound.scss'

// eslint-disable-next-line
const treeShakeBlocker = []

const NotFound = props =>
{
	const store = useContext(StoreContext)
	const data = useRef(null)

	useEffect(() => 
	{
		store.analytics.pageView('/notfound')

	},[])

	useEffect(() =>
	{
		if(store.pages.length)
		{
			data.current = _filter(store.pages, { slug: store.pageId })[0]
		}
		
	},[store.pages])
	
	return (
		<Fragment>
			<div 
				data-page-id='404' 
				className='page'
				style={props.styles}
			>
				<div className='content'>
					<p>Let's Get LOOOOSSSSTTTTTTT</p>
				</div>

				<Footer layout='normal' />
			</div>
		</Fragment>
	)
}

export default NotFound