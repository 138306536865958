import React from 'react'
import { BrowserRouter as Router,Link } from 'react-router-dom'
import './CompanyTagline.scss'

const CompanyTagline = props =>
{
	const companyTagline = props.children || 'MESSAGE - PUPPET'
	
	return (
		<Link 
			className="company-tagline"
			to='/about'
		>
			<div className="label">{companyTagline}</div>
			<div className="line"></div>
		</Link>
	)
}

export default CompanyTagline