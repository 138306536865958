import React from 'react'
import { SocialIcon } from 'react-social-icons'
import './SocialButton.scss'

const SocialButton = props =>
{
	return (
		<div className='social-button'>
			<SocialIcon url={props.url} />
		</div>
	)
}

export default SocialButton