/* global CA */
import React, { Fragment,useEffect,useRef,useContext,useState } from 'react'
import Helmet from 'react-helmet'
import _filter from 'lodash/filter'
import { StoreContext } from 'components/Data/StoreContext'
import Footer from 'components/Footer/Footer'
import CompanyTagline from 'components/CompanyTagline/CompanyTagline'
import Puppet from 'components/Puppet/Puppet'
//import Message from 'components/Puppet/Message'
import './Home.scss'

// eslint-disable-next-line
const treeShakeBlocker = []

const Home = props =>
{
	const store = useContext(StoreContext)
	const data = useRef(null)

	useEffect(() => 
	{
		store.analytics.pageView('/')

	},[])

	useEffect(() =>
	{
		if(store.pages.length)
		{
			data.current = _filter(store.pages, { slug: store.pageId })[0]
		}
		
	},[store.pages])

	return (
		<Fragment>
			<Helmet>
				<title>Message Puppet</title>
				<meta name='title' content='Message Puppet' />
			</Helmet>
			<div 
				data-page-id='home' 
				className='page'
				style={props.styles}
			>
				<div className='content'>
					<Puppet location={props.location} showModal={props.showModal}/>
					{/* just let the background video stuff show through */}
				</div>

				<CompanyTagline />

			</div>
		</Fragment>
	)
}

export default Home