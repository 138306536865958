/* global CA */
import React, { useState,useEffect } from 'react'
import { isTouch } from 'react-device-detect'
import { TweenLite,TimelineLite,Linear,Expo } from 'gsap/all'
import Preloader from 'preloader'
import _each from 'lodash/each'
import CountUp from 'react-countup'

import './Loader.scss'

// eslint-disable-next-line
const treeShakeBlocker = [Linear,Expo]

let animateCounter,animateIn,animateOut,startLoader
let pctLoaded_last = 0

const Loader = props =>
{
	const counterObj = { pct: 0 }
	const _refs = { el:null }
	
	const [_pctLoaded,setPctLoaded] = useState(0)
	const [_isMounted,setIsMounted] = useState(false)

	const onMount = e =>
	{
		if(e)
		{
			_refs.el = e
			setIsMounted(true)
		}
	}

	const initLoader = () => 
	{
		const loader = new Preloader({ xhrImages: true })
		loader.on('progress',progress => setPctLoaded(progress))
		loader.on('complete', () => setPctLoaded(1))

		// Add files manifest to loader
		//
			_each(
				props.files, 
				item =>
				{
					if(typeof item === 'object')
					{
						switch(item.type)
						{
							case 'json':
								loader.addJSON(item.url,{ ...item.options })
								break
								
							case 'video':
								loader.addVideo(item.url,{ ...item.options })
								break
								
							default:
								loader.add(item.url,{ ...item.options })
						}
					}
					else
					{
						loader.add(item)
					}
						
				}
			)

		if(typeof props.callbacks.onInit === 'function')
		{
			try { props.callbacks.onInit() }
			catch(e) {}
		}
		
		startLoader = () =>
		{
			loader.load()
		}
		
		if(typeof props.callbacks.onStart === 'function')
		{
			try 
			{ 
				props.callbacks.onStart({ 
					animateIn: animateIn,
					startLoader: startLoader
				}) 
			}
			catch(e) {}
		}
		else
		{
			loader.load()
		}

	}

	const initAnimations = () =>
	{
		animateIn = () =>
		{
			const { el } = _refs

			TweenLite.to(el, 0.75, { autoAlpha:1, ease:Expo.easeOut, onComplete:startLoader })
		}
		
		animateOut = () =>
		{
			const { el } = _refs

			TweenLite.to(el, 0.75, { autoAlpha:0, delay:0.25, ease:Expo.easeOut })
		}
		
		animateCounter = new TimelineLite({ paused:true })
		animateCounter.fromTo(counterObj, 1, 
			{
				pct: 0
			},
			{
				pct: 100,
				onUpdate: (el,counterObj) => 
				{
					el.innerText = counterObj.pct.toFixed(0)
				},
				onUpdateParams:[_refs.counter,counterObj],
				onComplete: (props,animateOut) =>
				{
					if(typeof props.callbacks.onComplete === 'function')
					{
						try { props.callbacks.onComplete({ animateOut }) }
						catch(e) {}
					}
					else
					{
						animateOut()
					}
				},
				onCompleteParams:[props,animateOut],
				ease:Linear.easeNone
			} 
		)
	}

	useEffect(() =>
	{
		if(_isMounted)
		{
			initLoader()
			initAnimations()
		}

	},[_isMounted])

	useEffect(() =>
	{
		if(_pctLoaded <= pctLoaded_last)
			return

		if(animateCounter)
		{
			TweenLite.to(animateCounter, 1, { progress:_pctLoaded, ease:Linear.easeNone })
		}

		if(typeof props.callbacks.onProgress === 'function')
		{
			try { props.callbacks.onProgress({ progress:_pctLoaded }) }
			catch(e) {}
		}

		pctLoaded_last = _pctLoaded


	},[_pctLoaded,props.callbacks])

	return (
		<div className='loader' ref={onMount}>
			<div className='bg'></div>
			<div className='content'>
				{/*<video 
					className='logo-loop'
					src={`${process.env.PUBLIC_URL}/assets/preloader.mp4`}
					autoPlay={!isTouch}
					loop
					muted
				/>*/}
				{/*
				<div className='progress'>
					<div className='loaded' style={{ transform:`scaleX(${_pctLoaded})`}}></div>
				</div>
				*/}
				
				<p className='counter' ref={e => { if(e) _refs.counter = e }}>
					<CountUp end={_pctLoaded * 100} />
				</p>
			</div>
			{ props.children && props.children(_pctLoaded) }
		</div>
	)
}

Loader.defaultProps =
{
	files: [],
	callbacks: {}
}

export default Loader