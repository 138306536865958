//import React from 'react'
import ReactGA from 'react-ga'
import _map from 'lodash/map'

const noop = () => {}

class Analytics
{
	options
	trackers
	
	constructor(ids,options={})
	{
		this.options = options
		
		if(this.options.disabled)
			return this

		this.trackers = []
		
		return ReactGA.initialize(_map(ids, v => 
		{
			const isString = typeof v === 'string'
			const isObject = typeof v === 'object' && !Array.isArray(v)
			
			switch(true)
			{
				case isObject:
					let id = v.id
					v.id = null
					delete v.id

					v = { id:id, options: {...v}}
					
					break
					
				case isString:
				default:

					v = { id:v, options:{ ...options.options }}

					break
			}

			const trackerId =  `tracker_${v.id.replace(/[^\w]+/g,'')}`
			this.trackers.push(trackerId)

			return {
				trackingId: v.id,
				gaOptions:
				{
					name: trackerId,
					...v.options
				}
			}
		}), 
		{ 
			debug: options.debug, 
			alwaysSendToDefaultTracker: false 
		})
	}

	pageView(url)
	{
		if(this.options.disabled)
			return noop
			
		url = url || window.location.pathname

		ReactGA.pageview(url,[...this.trackers])
	}

	modalView(modalName)
	{
		if(this.options.disabled)
			return noop
			
		ReactGA.modalview(modalName)
	}

	event()
	{
		if(this.options.disabled)
			return noop
			
		/*
		category: 'Promotion',
		action: 'Displayed Promotional Widget',
		label: 'Homepage Thing',
		nonInteraction: true
		*/

		console.log([...arguments])

		ReactGA.event(
			{
				...arguments	
			},
			[...this.trackers]
		)
	}

}

export default Analytics