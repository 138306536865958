import React from 'react'
import _extend from 'lodash/extend'

const Logo = props => 
{
	const defaultProps = 
	{
		logging: false,
		color: '#fef600'
	}
	
	props = _extend(defaultProps,props)

	if(props.logging)
		console.log('<Logo />',{ props })

	return (
		<svg 
			className={`logo ${props.className ? props.className : ''}`} 
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 563.8 96"
			onClick={e => {
				if(props.onClick) {
					try {
						props.onClick(e);
					} catch(e) {}
				}
			}}
		>
			<path fill={props.color} d="M71.7 73.8c0 15.6-9.4 22.2-25.5 22.2H25.5C9.4 96 0 89.4 0 73.8V22.2C0 6.6 9.4 0 25.5 0h20.6c16.1 0 25.5 6.6 25.5 22.2v7.3l-19.6 5v-8.8c0-5.2-2.2-7.6-8.6-7.6H28.2c-6.3 0-8.6 2.4-8.6 7.6V70c0 5.2 2.2 7.6 8.6 7.6h15.3c6.3 0 8.6-2.4 8.6-7.6V59.9l19.6 5.6v8.3zM106.6 96c-16.1 0-25.5-6.6-25.5-22.2V22.2C81.1 6.6 90.5 0 106.6 0h23.7c16.1 0 25.5 6.6 25.5 22.2v51.6c0 15.6-9.4 22.2-25.5 22.2h-23.7zm21.1-18.4c6.3 0 8.6-2.4 8.6-7.6V25.7c0-5.2-2.2-7.6-8.6-7.6h-18.2c-6.3 0-8.6 2.4-8.6 7.6V70c0 5.2 2.2 7.6 8.6 7.6h18.2zM222.1 96l-34.7-58h-.8v58H167V0h18.9l34.8 59.5h.8V0h19.8v96h-19.2zM320 73.8c0 15.6-9.4 22.2-25.5 22.2h-20.6c-16.1 0-25.5-6.6-25.5-22.2V22.2c0-15.6 9.4-22.2 25.5-22.2h20.6C310.6 0 320 6.6 320 22.2v7.3l-19.6 5.1v-8.8c0-5.2-2.2-7.6-8.6-7.6h-15.3c-6.3 0-8.6 2.4-8.6 7.6V70c0 5.2 2.2 7.6 8.6 7.6h15.3c6.3 0 8.6-2.4 8.6-7.6V59.9l19.6 5.6v8.3zM333.3 96V0h66.9v18.1h-47.1v19.8h38.4v17.8h-38.4v21.9h47.1V96h-66.9zm145.3-58.3c0 15.6-9.4 22.2-25.5 22.2h-24V96h-19.8V0h43.8c16.1 0 25.5 6.6 25.5 22.2v15.5zm-19.6-12c0-5.2-2.2-7.6-8.6-7.6h-21.3v24h21.3c6.3 0 8.6-3.4 8.6-8.7v-7.7zm74.8-7.6V96h-19.6V18.1H484V0h79.8v18.1h-30z" />
		</svg>
	)
}

export default Logo