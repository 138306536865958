/* global CA */
import React, { Fragment,lazy,Suspense,useState,useEffect,useCallback,useContext } from 'react'
import { BrowserRouter as Router,Link,withRouter } from 'react-router-dom'
import { isTouch } from 'react-device-detect'
import { Spring,animated,config } from 'react-spring/renderprops'
import { TweenLite,TimelineLite,CSSPlugin,Expo } from 'gsap/all'
import Helmet from 'react-helmet'
import _map from 'lodash/map'
import _each from 'lodash/each'

import EventDispatcher from '@ca/eventdispatcher'
import { StoreContext } from 'components/Data/StoreContext'

import Masthead from './Masthead/Masthead'
import Nav from './Nav/Nav'
import NavLinks from './NavLinks/NavLinks'
import Modal from './Modal/Modal'
import Header from './Header/Header'
import Loader from './Loader/Loader'
import Swipe from './Swipe/Swipe'
import Main from './Main'
import { ReactComponent as Logo } from '../assets/images/logo.svg'



// async imports
//
const StaticBackground = lazy(() => import('./StaticBackground/StaticBackground'))
const VideoBackground = lazy(() => import('./VideoBackground/VideoBackground'))
const Background = isTouch || isTouch ? StaticBackground : VideoBackground

// eslint-disable-next-line
const treeShakeBlocker = [CSSPlugin]

const Layout = props =>
{
	const location = props.location.pathname
	const store = useContext(StoreContext)

	store.pageId = location.split('/').slice()[1]

	const [_location,setLocation] = useState(location)
	//const [_dataReady,setDataReady] = useState(props.dataReady || false)
	const [_navOpen,setNavOpen] = useState(false)
	const [_doSwipe,setDoSwipe] = useState(false)
	const [_initEventHandlers,setInitEventHandlers] = useState(false)
	const [_mouseX,setMouseX] = useState(false)
	const [_mouseY,setMouseY] = useState(false)
	const [_scrollY,setScrollY] = useState(false)
	const [_bgFX,setBgFx] = useState(0)
	const [_modalData,setModalData] = useState(null)
	const [_modalOpen,setModalOpen] = useState(false)
	
	const showModal = useCallback((props) =>
	{

		setModalData(props)
		setModalOpen(true)

	},[]);

	store.showModal = showModal;

	const closeModal = () =>
	{
		setModalData(null)
		setModalOpen(false)
	}

	const revealSite = ({ startLoader }) =>
	{
		const root = document.querySelector('#root')
		const loader_content = document.querySelector('#root > .loader .content')
		
		const TL = new TimelineLite()
		TL
			.to(root, 0.6, { autoAlpha:1, ease:Expo.easeOut })
			.fromTo(loader_content, 1, { autoAlpha:0 }, { immediateRender:true, autoAlpha:1, ease:Expo.easeOut },'-=0')
			.call(() => startLoader(),[],null,'-=1')
		;
	}

	const onMouseMove = e =>
	{
		let x = e.pageX
		let y = e.pageY
		
		const top = e.pageY
		const right = document.body.clientWidth - e.pageX
		const bottom = document.body.clientHeight - e.pageY
		const left = e.pageX

		if(top < 10 || bottom < 10)
			y = -1
		
		if(right < 10 || left < 10)
			x = -1

		setMouseX(x)
		setMouseY(y)
	}

	const onMouseScroll = e =>
	{
		
	}

	const prepareLoaderFiles = () =>
	{
		// Get stuff listed under Assets
		let filesArray = _map(store.settings.assets, ({ type, url, dataOptions }) => {
			let options = {}
			
			if(dataOptions)
			{
				options.onComplete = (data) =>
				{
					const dataKey = dataOptions.key || url.split('.').slice(-1)[0]
					const dataRoot = (dataOptions.root && dataOptions.root.split('.')) || ['']

					//window.CA.Data[dataKey] = dataRoot[0] ? eval(`data["${dataRoot.join('][')}"]`) : data
					store.set(dataKey,dataRoot[0] ? eval(`data["${dataRoot.join('][')}"]`) : data)
				}
			}
			
			return {
				type,
				url,
				options
			}
		})

		_each(store.settings.characters, character => 
		{
			let assetType = "image";
			let faceOptions = {};
			let mouthOptions = {};

			faceOptions.onComplete = data =>
			{
				let dataKey = character.uuid+"-face";
				store.characterAssets[dataKey] = data;
			}

			mouthOptions.onComplete = data =>
			{
				let dataKey = character.uuid+"-mouth";
				store.characterAssets[dataKey] = data;
			}

			filesArray.push({
				"type":assetType,
				"url":character.face.url,
				"options":faceOptions
			});

			filesArray.push({
				"type":assetType,
				"url":character.mouth.url,
				"options":mouthOptions
			});
		})

		return filesArray
	}

	// init stuff
	//
	useEffect(() =>
	{
		//TweenLite.ticker.addEventListener('tick', () => EventDispatcher.dispatch('gsap-tick'))
		EventDispatcher.on('page-scroll', ({ target, scrollTop }) =>
		{
			const y = Math.min(scrollTop / (window.innerHeight / 2.5),1)
			setScrollY(y)

			//console.log(this.id.toUpperCase()+'.onPageScroll()',{ value });

			/*
			this.scrollingelement = e.target;
			//EventDispatcher.dispatch('page-scrolling');
			EventDispatcher.dispatch('scroll-tick', { target: e.target });
			
			const caLogo = this._refs['ca-logo'];
			const scrollPrompt = this._refs['scroll-prompt'];
			const topArrow = document.querySelector('#navigation .top');
			const scrollTop = e.scrollTop;

			let value = Math.min(scrollTop / (window.innerHeight / 2.5),1);

			//console.log(this.id.toUpperCase()+'.onPageScroll()',{ value });

			if(caLogo)
				TweenLite.set(caLogo, { autoAlpha:(1 - value), ease:Quint.easeOut });

			if(scrollPrompt)
				TweenLite.set(scrollPrompt, { autoAlpha:(1 - value), ease:Quint.easeOut });

			if(topArrow)
				TweenLite.set(topArrow, { autoAlpha:value, ease:Quint.easeOut });
			*/
		})

	},[])

	// Add Event Handlers
	//
	useEffect(() => 
	{
		//document.addEventListener('mousemove',onMouseMove)

		setInitEventHandlers(true)

	},[_initEventHandlers])

	
	// UI updates on page change
	//
	useEffect(() => 
	{
		if(location !== _location)
		{
			setNavOpen(false)
			setLocation(location)
			setDoSwipe(!_doSwipe)
			setBgFx(store.pageId === '')

			store.history.push(location)
			
			return
		}

	},[_location,location,_doSwipe])



	return (
		<Fragment>
			
			<Helmet>
				<meta charSet='utf-8' />
				<title>Concept Arts</title>
				<meta name='title' content='Concept Arts' />
				<meta name='description' content='Concept Arts is a Los Angeles based agency working across all platforms. With decades of experience and countless awards, Concept Arts has consistently crafted the most iconic marketing campaigns in entertainment.' />
				<meta name='keyword' content='creative,agency,los angeles,digital,design,print,social,a/v,trailers' />
			</Helmet>

			<Suspense fallback={<div className='suspense'></div>}>
			{/*
				<Background 
					fx={_bgFX}
					mouseX={_mouseX}
					mouseY={_mouseY}
				/>
			*/}
			</Suspense>

			<Masthead 
				
			/>

			{
			/*<Nav 
				isOpen={_navOpen}
				onClick={e => setNavOpen(true)}
				onMouseLeave={e => setNavOpen(false)}
			>
				<NavLinks />
			</Nav>*/
			}

			<Header 
				
			/>

			<Main/>

			<Modal {..._modalData}closeModal={closeModal}/>

			<Loader
				//seems to get called repeatedly -- bug??
				files={prepareLoaderFiles()}
					
				callbacks={{
					onStart: ({ startLoader }) =>
					{
						if(window.CA.Debug)
							console.log('Loader.callbacks.onStart()',{ startLoader })

						revealSite({ startLoader })
					},
					onProgress: ({ progress }) =>
					{
						if(window.CA.Debug)
							console.log('Loader.callbacks.onProgress()',{ progress })
					},
					onComplete: ({ animateOut }) =>
					{
						if(window.CA.Debug)
							console.log('Loader.callbacks.onComplete()')

						console.log(store);

						store.dataReady = true
						
						if(animateOut)
							animateOut()

						setDoSwipe(!_doSwipe)

						setTimeout(() => { setBgFx(true) },1250)
					}
				}}
			>
				{/* pctLoaded => {
					return (
						<p>{pctLoaded}</p>
					)
				}*/}
			</Loader>

			<Swipe 
				animate={_doSwipe} 
			/>
			
			<div 
				id='border-frame'
				className={_navOpen ? 'nav-open' : ''}
			></div>

		</Fragment>
	)
}

export default withRouter(Layout)