import React,{ Fragment, useContext, useRef, useState, useReducer } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'


import './Start.scss'

const Start = props =>
{
	return (
		<div id="start">
			<div className="controls">
				<button onClick={props.startMicrophone}>
					{'Record Message'}
				</button>
			</div>
		</div>
	);
}

export default Start