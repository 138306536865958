import React,{ Fragment, useContext, useRef, useState, useEffect } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'
import Start from 'components/Puppet/Start'
import CharacterSelect from 'components/Puppet/CharacterSelect'
import RecordAudio from 'components/Puppet/RecordAudio'
import LoadMessage from 'components/Puppet/LoadMessage'
import Message from 'components/Puppet/Message'
import { StoreContext } from 'components/Data/StoreContext'

import './Puppet.scss'

const Puppet = props =>
{
	const store = useContext(StoreContext)
	const [microphone,setMicrophone] = useState(null)
	const [characterSelected,setCharacterSelected] = useState(false)
	const [messageID,setMessageID] = useState(null)
	const [messagePermission,setMessagePermission] = useState(false)
	const characterID = useRef(0);

	useEffect(() =>
	{
		console.log("Puppet::useEffect");

		const query = new URLSearchParams(props.location.search);
		const token = query.get('messageid');

		if(token)
		{
			setMessageID(token);
		}

	},[])

	const getMicrophone = () => 
	{
		if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
		{
			navigator.mediaDevices.getUserMedia({

				audio: true, 
				video: false 
				
			}).then((stream) => {

				store.microphone = stream;
				setMicrophone(stream);

			}).catch((err) => {

				store.showModal({type:'error',message:'Microphone access is required to record a message!'});
			
			});
		}
		else
		{

			store.showModal({type:'error',message:'It appears that your browser does not support microphone access...sad'});
		
		}
	}

	/*const stopMicrophone = () =>
	{
		microphone.getTracks().forEach(track => track.stop());
		setMicrophone(null);
	}*/

	const startMicrophone = () => 
	{
		if(!store.audioContext)
		{
			store.audioContext = new (window.AudioContext || window.webkitAudioContext)();
		}

		if (!microphone)
		{
			getMicrophone();
		} 
	}

	const start = () =>
	{		
		console.log("Puppet::start");
		return(<Start startMicrophone={startMicrophone}/>);
		/*return(
				<StageTest callback={startMicrophone}/>
			);*/
	}

	const selectCharacter = (id) =>
	{
		console.log("select this character!!!");
		characterID.current = id;
		setCharacterSelected(true);
	}

	const reselectCharacter = () =>
	{
		console.log("reselect a character!!!");
		characterID.current = 0;
		setCharacterSelected(false);
	}

	const characterSelect = () =>
	{		
		return(<CharacterSelect characterSelected={characterSelected} selectCallback={selectCharacter}/>);
	}

	const recordAudio = () =>
	{
		return (<RecordAudio characterID={characterID.current} characterSelected={characterSelected} reselectCallback={reselectCharacter} />);
	}

	const loadMessage = () =>
	{
		if(!store.audioContext)
		{
			store.audioContext = new (window.AudioContext || window.webkitAudioContext)();
		}

		console.log("Puppet::loadMessage");

		setMessagePermission(true);
	}

	const load = () =>
	{
		return (<LoadMessage loadMessage={loadMessage}/>);
	}

	const message = () =>
	{
		return (<Message messageID={messageID} />);
	}

	const Step = () =>
	{
		let markup = '';
		
		if(messageID)
		{
			if(!messagePermission)
			{
				markup = load();
			}
			else
			{
				markup = message();
			}
		}
		else
		{
			if(!microphone)
			{
				markup = start();
			}
			else
			{
				markup = recordAudio();
			}
		}

		return markup;
	}

	return (
		<Fragment>
			<Step/>
		</Fragment>
	);
}

export default React.memo(Puppet)