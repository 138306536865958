import React, { useEffect } from 'react'

import './Header.scss'

const Header = props =>
{
	// init stuff
	//
	useEffect(() =>
	{
		

	},[])
	
	
	return (
		<header 
			id='header'
		>
		</header>
	)
}

export default Header