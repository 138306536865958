import React, { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as EmailIcon} from '../../assets/images/email-icon.svg'
import SocialLinks from 'components/Social/SocialLinks'
import './MiniLayout.scss'

const MiniLayout = ({ data }) =>
{
	return (
		<Fragment>
			<div className='copyright-mini'>{data.copyright}</div>

			<div className='connect-mini'>
				<div className='greeting'>{data.phone_number.message}</div>
				<a className='phone' href={`tel:${data.phone_number.dial}`}>{data.phone_number.number}</a>
				<a 
					data-tip='React-tooltip'
					className='email' 
					href={`mailto:${data.email_encoded}`}
				>
					<EmailIcon 
						className='email-icon' 
					/>
				</a>
				<ReactTooltip 
					className='tooltip' 
					place='top' 
					type='dark' 
					//effect='solid'
				>
					SAY HELLO!
				</ReactTooltip>

				<SocialLinks />
			</div>
		</Fragment>
	)
}

export default MiniLayout