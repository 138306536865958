import React from 'react'
import Logo from 'components/UI/Logo'
import NavLinks from 'components/NavLinks/NavLinks'
import CompanyTagline from 'components/CompanyTagline/CompanyTagline'
import './NormalLayout.scss'

const NormalLayout = ({ data }) =>
{
	return (
		<div className='connect-normal'>
			<div className='container'>
				<div className='col1'>
					<Logo className='tt' />
					<div className='address'>{data.address}</div>
					<div className='greeting'>{data.phone_number.message}</div>
					<a className='phone' href={`tel:${data.phone_number.dial}`}>{data.phone_number.number}</a>
					<a 
						className='email' 
						href={`mailto:${data.email_encoded}`}
					>
						{data.email}
					</a>
					<div className='copyright'>{data.copyright}</div>
				</div>
				<div className='col2'>
					<div 
						className='nav-menu-small'
					>
						<NavLinks />
					</div>
					<CompanyTagline />
				</div>
			</div>
		</div>
	)
}

export default NormalLayout