import React,{ Fragment, useContext, useRef, useState, useReducer } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'


import './LoadMessage.scss'

const LoadMessage = props =>
{
	return (
		<div id="load-message">
			<div className="controls">
				<button onClick={props.loadMessage}>
					{'Load Message'}
				</button>
			</div>
		</div>
	);
}

export default LoadMessage