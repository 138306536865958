import React, { useState,useEffect,useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import axios from 'axios'
import { StoreContext } from 'components/Data/StoreContext'

import Layout from 'components/Layout'
import EventDispatcher from '@ca/eventdispatcher'
import Analytics from 'components/Analytics/Analytics'

import 'styles/main.scss'

if(typeof window !== 'undefined')
{
	// SSR stuff in here...
}

const App = props =>
{
	const [_init,setInit] = useState(false)
	const data = useRef({
		settings: {},
		debug: false,
		analytics: null,
		options: {},
		pages: [],
		projects: [],
		characterAssets: {},
		pageId: null,
		dataReady: false,
		history: [],
		eventDispatcher: EventDispatcher,
		set: function(key,values)
		{
			this[key] = values
		}
	})
	

	useEffect(() =>
	{
		const fetchSettings = async () =>
		{
			const request = await axios.get(`${process.env.PUBLIC_URL}/settings.json`)
			const json = await request.data

			data.current.set('settings' ,json)
			if(typeof json.debug !== 'undefined')
				data.current.set('debug',json.debug)

			data.current.set('analytics',new Analytics(
				[...json.analytics.GA],
				{ 
					debug: json.analytics.debug, 
					disabled: !json.analytics.enabled 
				}
			))

			data.current.set('dataReady',true)

			setInit(true)
		}

		try 
		{
			fetchSettings()
		}
		catch(exception)
		{
			console.log({ exception })
		}

	},[])

	return (
		<Router>
			<StoreContext.Provider value={data.current}>
				{ _init ? <Layout /> : null }
			</StoreContext.Provider> 
		</Router>
	)
}

export default App