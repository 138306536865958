import React,{ Fragment,useState,useRef,useContext } from 'react'
import _each from 'lodash/each'
import { isTouch } from 'react-device-detect'
import _isEmpty from 'lodash/isEmpty'
import { StoreContext } from 'components/Data/StoreContext'
import SocialButton from './SocialButton' //'@ca/social-button'
import { ReactComponent as LinkIcon } from 'assets/images/link-icon.svg'
import './SocialLinks.scss'

const SocialLinks = props =>
{
	let store = useContext(StoreContext)
	const data = useRef()
	const [_panelOpen,setPanelOpen] = useState(false)
	
	if(!_isEmpty(store.options))
	{
		data.current = 
		{
			links: store.options.social_links,
			label: store.options.social_label
		}
	}
	else
	{
		return null
	}
	
	let buttons = []
	
	_each(data.current.links, (dprops,i) => 
	{
		if(!dprops || !dprops.active)
			return

		buttons.push(
			<SocialButton 
				key={'social-button-'+dprops.platform} 
				url={dprops.url}
				onClick={e => 
				{
					e.stopPropagation()
					if(isTouch)
						togglePanel()
				}}
			/>
		)
	})

	/*
	let label = options.social_label;
	let linkIcon = this.state.siteLoaded && CA.svg['link-icon'] ? CA.svg['link-icon'] : '';
	let onMouseEnter = Utils.isDesktop() ? this.toggleSocialLinks.bind(this) : null;
	let onMouseLeave = Utils.isDesktop() ? this.toggleSocialLinks.bind(this) : null;
	let onClick = Utils.isTouch() ? this.toggleSocialLinks.bind(this) : null;
	*/

	const showPanel = () =>
	{
		setPanelOpen(!_panelOpen)
	}

	const hidePanel = () =>
	{
		setPanelOpen(false)
	}

	const togglePanel = () =>
	{
		setPanelOpen(!_panelOpen)
	}

	const onMouseEnter = e =>
	{
		showPanel()
	}

	const onMouseLeave = e =>
	{
		hidePanel()
	}

	const onClick = e =>
	{
		togglePanel()
	}

	
	
	return (
		<div 
			className={`social-links ${_panelOpen ? 'open' : ''}`}
			onMouseEnter={!isTouch ? onMouseEnter : null} 
			onMouseLeave={!isTouch ? onMouseLeave : null}
		>
			<div
				className="toggle-btn" 
				onClick={isTouch ? onClick : null}
			>
				<LinkIcon />
			</div>

			<div className="panel">
				{buttons}
				<div className="line"></div>
				<div className="label" onClick={isTouch ? onClick : null}>{data.current.label}</div>
			</div>
		</div>
	);
	
	
	/*
	return (
		<Fragment>
			<div className='sprite bg_social'></div>
			<div className='buttons-wrapper'>
				<SocialButton
					analytics={props.analytics}
					actionType='follow'
					platformId='facebook'
					link='https://www.facebook.com/DearWhitePeople/'
				/>
				<SocialButton
					analytics={props.analytics}
					actionType='follow'
					platformId='twitter'
					link='https://twitter.com/DearWhitePeople'
				/>
				<SocialButton
					analytics={props.analytics}
					actionType='follow'
					platformId='instagram'
					link='https://www.instagram.com/DearWhitePeople/'
				/>
			</div>
		</Fragment>
	)
	*/
}

export default SocialLinks