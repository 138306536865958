import React, { Fragment,useState,useEffect } from 'react'
import { TimelineLite,CSSPlugin,Expo } from 'gsap/all'
import './Nav.scss'

const treeShakeBlocker = [CSSPlugin]

const NavMenu = props => 
{
	const [_isOpen,setIsOpen] = useState(props.isOpen)
	const isOpen = props.isOpen
	
	useEffect(() =>
	{
		if(isOpen !== _isOpen)
		{
			setIsOpen(isOpen)
			return
		}
			
		const TL = new TimelineLite()
		
		if(_isOpen)
		{
			TL.staggerFromTo('.nav-menu a',0.75, { autoAlpha:0, y:'350%' }, { autoAlpha:1, y:'0%', ease:Expo.easeOut },0.03,0.1)
		}
		else
		{
			TL.to('.nav-menu a', 0.25, { autoAlpha:0 },0)
		}

	},[_isOpen,isOpen])
	
	return (
		<Fragment>
			<div 
				className={`nav-menu ${_isOpen ? 'nav-open' : ''}`}
				onMouseLeave={props.onMouseLeave}
			>
				{props.children}
			</div>
			<div 
				className={`nav-burger ${_isOpen ? 'nav-open' : ''}`}
				onClick={props.onClick}
			>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</Fragment>
	)
}

export default NavMenu